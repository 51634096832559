import stepMixin from '../../../mixins/stepMixin'
import FormInput from '../../form/formInput/FormInput.vue'
import { maxMileageInput } from '@/constants/general'

export default {
    name: 'Mileage',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin],

    components: {
        FormInput
    },

    data() {
        return {
            maxMileageInput: () => maxMileageInput,
            maxMileageExeeded: () => (this.field.mileage > maxMileageInput)
        }
    },

    methods: {
        validate() {
            let isValid = true

            if (this.isEmpty(this.field.mileage)) {
                isValid = false
            }

            if (this.maxMileageExeeded()) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        }
    }
}
